import { Outlet } from "react-router-dom";

const GrammarlyPageLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default GrammarlyPageLayout;
