import React from "react";
import "./styles.css";
import { Button } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getData, postData, postFormData } from "../../../shared/utils";
import { SliderCustom } from "../../../components/common/slider.component";
import myUIConstants from "../../../shared/constants/constants";
const speakerNameList = ["EN-US", "EN-BR", "EN_INDIA", "EN-AU", "EN-Default"];
const languageCodeList = ["EN"];

class TTSMeloComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isCreate: true,
      documentIndex: -1,
      fileName: "",
      fileContents: "",
      speakerName: speakerNameList[0],
      aiRequestWaiting: false,
      src: "",
      blob: null,
      isAudioErr: false,
      maxTextLen: 4000,
      codeToVoiceObjArrMap: null,
      nameToSsmlGenderMap: null,
      pitch: 0,
      speed: 1,
      languageCode: languageCodeList[0],
      ssmlGender: "",
    };
  }

  async componentDidMount() {}

  setSelected(ind) {
    this.setState({ documentIndex: ind, isCreate: false });
  }

  onPress(user) {
    this.setState({ curr_user: user });
  }

  onFileChange = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      this.setState({ fileContents: text.substring(0, this.state.maxTextLen) });
    };
    reader.readAsText(event.target.files[0]);
    this.setState({ fileName: event.target.files[0].name });
  };

  onSpeakingNameChange = (event) => {
    event.preventDefault();
    this.setState({
      speakerName: event.target.value,
    });
  };

  onLangageCodeChange = (event) => {
    event.preventDefault();
    this.setState({
      languageCode: event.target.value,
    });
  };

  async onGetAIAudio(event) {
    event.preventDefault();
    if (this.state.fileContents.length === 0) return;
    this.setState({ aiRequestWaiting: true, isAudioErr: false });
    let formData = new FormData();
    formData.append("text", this.state.fileContents);
    formData.append("speaker", this.state.speakerName);
    formData.append("speed", this.state.speed);
    postFormData(process.env.REACT_APP_MELO_TTS_API_URL, formData)
      .then((data) => {
        return data.blob();
      })
      .then((blob) => {
        this.setState({
          blob,
          aiRequestWaiting: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isAudioErr: true });
      });
  }

  generateLangCodeComponents() {
    const componentArr = [];
    languageCodeList.forEach((val, key) => {
      componentArr.push(<MenuItem value={val}>{val}</MenuItem>);
    });
    return componentArr;
  }

  generateSpeakingNameComponents() {
    const componentArr = [];
    for (const speaker of speakerNameList) {
      componentArr.push(<MenuItem value={speaker}>{speaker}</MenuItem>);
    }
    return componentArr;
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <div
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
              marginBottom: 50,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ margin: 10 }}>Custom TTS (Melo)</h2>
            <p> Price: Free (ML003)</p>
          </div>
          <div>
            <input
              style={{
                padding: 20,
                border: "1px solid grey",
                display: "flex",
                flexDirection: "column",
              }}
              type="file"
              onChange={this.onFileChange}
            />
          </div>
          <div style={{ width: "100%" }}>
            <h3>Document contents: {this.state.fileName}</h3>
            <textarea
              fullWidth
              width={1000}
              maxLength={4000}
              multiline={true}
              readOnly={false}
              rows={50}
              style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
              size="small"
              color="primary"
              type="text"
              value={this.state.fileContents}
              onChange={(e) => {
                this.setState({
                  fileContents: e.target.value.substring(
                    0,
                    this.state.maxTextLen
                  ),
                });
              }}
            ></textarea>
            <div id="the-count">
              <span id="current">{this.state.fileContents.length}</span>
              <span id="maximum">/ {this.state.maxTextLen}</span>
            </div>
          </div>
          <h3>Generate Audio</h3>
          <div
            style={{
              padding: 20,
              border: "1px solid grey",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "50%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 40, width: "50%" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Langage Code
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.languageCode}
                      onChange={this.onLangageCodeChange}
                      label="Speaking Name"
                    >
                      {this.generateLangCodeComponents()}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 40, width: "50%" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Speaker Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.speakerName}
                      onChange={this.onSpeakingNameChange}
                      label="Speaking Name"
                    >
                      {this.generateSpeakingNameComponents()}
                    </Select>
                  </FormControl>
                </div>

                <SliderCustom
                  value={this.state.speed}
                  setValue={(val) => this.setState({ speed: val })}
                  title={"Speed"}
                  min={0.25}
                  max={4}
                  step={0.01}
                  defaultValue={1}
                ></SliderCustom>
              </div>

              <Button
                variant="contained"
                style={{ height: "10", width: "30%" }}
                disabled={
                  this.state.speakerName.length === 0 ||
                  this.state.fileContents.length === 0 ||
                  this.state.aiRequestWaiting
                }
                onClick={(e) => {
                  this.onGetAIAudio(e);
                }}
              >
                Create
              </Button>
            </div>

            <div style={{ paddingTop: 20 }}>
              {this.state.blob ? (
                <ReactAudioPlayer
                  src={URL.createObjectURL(this.state.blob)}
                  controls
                />
              ) : this.state.isAudioErr ? (
                <p>Audio Generation Error</p>
              ) : (
                <p>No audio</p>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "300px",
            height: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <div>
            <h3 style={{ marginTop: 0 }}>Select the document</h3>
            <Button
              onClick={() => {
                this.setState({ isCreate: true });
              }}
              style={{
                width: "100%",
                height: "100px",
                textTransform: "none",
                fontSize: "15px",
                color: "black",
                backgroundColor: this.state.isCreate ? "grey" : "white",
                borderColor: "black",
                marginBottom: "10px",
              }}
              variant="outlined"
            >
              Create new
            </Button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default TTSMeloComponent;
