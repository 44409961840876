import { Refresh, TrendingDown, VolumeUp } from "@mui/icons-material";
import {
  Box,
  Button,
  css,
  Divider,
  FormGroup,
  Grid,
  Input,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { SliderCustom } from "../../components/common/slider.component";
import myUIConstants from "../../shared/constants/constants";
import React from "react";
import { TPromptData } from "shared/types";
// require('dotenv').config()

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response; // parses JSON response into native JavaScript objects
}

async function getData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  return response; // parses JSON response into native JavaScript objects
}

function send(
  prompt: string,
  min_len: number,
  max_len: number,
  len_penalty: number,
  beam_size: number,
  repetition_penalty: number,
  top_p: number,
  ainame: string,
  username: string,
  temperature: number
) {
  postData(
    `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/write/image/blip/prompt`,
    {
      prompt,
      min_len,
      top_p,
      len_penalty,
      beam_size,
      repetition_penalty,
      max_len,
      ainame,
      username,
      temperature,
    }
  );
}

function BlipPrompt() {
  const text =
    "child name must be {CHILDNAME}, ai name must be {AINAME}, your input must be {INPUT} (required), last N turns must be {CHAT_HISTORY}";
  const [prompt, set_prompt] = useState("");
  const [max_len, set_max_len] = useState(-1);
  const [min_len, set_min_len] = useState(-1);
  const [beam_size, set_beam_size] = useState(-1);
  const [len_penalty, set_len_penalty] = useState(-1);
  const [repetition_penalty, set_repetition_penalty] = useState(-1);
  const [top_p, set_top_p] = useState(-1);
  const [received, set_received] = useState(false);
  const [username, set_username] = useState("");
  const [ainame, set_ainame] = useState("");
  const [temperature, set_temperature] = useState(-1);
  if (!received)
    getData(
      `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read/image/blip/prompt`
    )
      .then((rsp) => rsp.json())
      .then((data: TPromptData) => {
        set_prompt(data.prompt);
        set_max_len(data.max_len);
        set_min_len(data.min_len);
        set_beam_size(data.beam_size);
        set_len_penalty(data.len_penalty);
        set_repetition_penalty(data.repetition_penalty);
        set_top_p(data.top_p);
        set_received(true);
        set_ainame(data.ainame);
        set_username(data.username);
        set_temperature(data.temperature);
      });
  return (
    <div
      style={{
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10%",
      }}
    >
      <form
        style={{
          width: "80%",
          // height: "1000px",
          justifyContent: "center",
          alignItems: "center",
        }}
        onSubmit={(event) => {
          event.preventDefault();
          send(
            prompt,
            min_len,
            max_len,
            len_penalty,
            beam_size,
            repetition_penalty,
            top_p,
            ainame,
            username,
            temperature
          );
        }}
      >
        <h2
          style={{ textAlign: "center", fontFamily: myUIConstants.fontFamily }}
        >
          Image Interpretation Prompt
        </h2>
        <div style={{ marginBottom: 10 }}>
          {" "}
          <span style={{ fontSize: 20, fontWeight: "bold" }}>Prompt</span>:{" "}
          <span style={{ fontSize: 15, fontWeight: "bold" }}> ({text})</span>
        </div>
        <TextField
          fullWidth
          multiline={true}
          rows={20}
          style={{ marginBottom: 20 }}
          size="small"
          color="primary"
          type="text"
          value={prompt}
          onChange={(event) => {
            set_prompt(event.target.value);
          }}
          placeholder="Enter Text"
        ></TextField>

        <SliderCustom
          value={min_len}
          setValue={set_min_len}
          title={"Min Length"}
          min={0}
          max={500}
          step={1}
          onChange={() => {}}
        ></SliderCustom>
        <SliderCustom
          value={max_len}
          setValue={set_max_len}
          title={"Max Length"}
          min={0}
          max={500}
          step={1}
          onChange={() => {}}
        ></SliderCustom>
        <SliderCustom
          value={beam_size}
          setValue={set_beam_size}
          title={"Beam Size"}
          min={0}
          max={10}
          step={1}
          onChange={() => {}}
        ></SliderCustom>
        <SliderCustom
          value={len_penalty}
          setValue={set_len_penalty}
          title={"Length Penalty"}
          min={0}
          max={2}
          step={0.01}
          onChange={() => {}}
        ></SliderCustom>
        <SliderCustom
          value={repetition_penalty}
          setValue={set_repetition_penalty}
          title={"Repetition Penalty"}
          min={0}
          max={2}
          step={0.01}
          onChange={() => {}}
        ></SliderCustom>
        <SliderCustom
          value={top_p}
          setValue={set_top_p}
          title={"Top p"}
          min={0}
          max={1}
          step={0.01}
          onChange={() => {}}
        ></SliderCustom>
        <SliderCustom
          value={temperature}
          setValue={set_temperature}
          title={"Temperature"}
          min={0}
          max={1}
          step={0.01}
          onChange={() => {}}
        ></SliderCustom>
        <text style={{ color: "red" }}>
          Warning! Changing temperature might cause errors in the output
          generation. In that case bring it back to 1.
        </text>
        <br></br>
        <br></br>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>{"CHILDNAME:  "}</p>
          <TextField
            multiline={true}
            rows={1}
            style={{ marginBottom: 20, marginTop: 10 }}
            size="small"
            type="text"
            value={username}
            onChange={(event) => {
              set_username(event.target.value);
            }}
            placeholder="child name"
          >
            {" "}
          </TextField>
        </div>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>{"AINAME:  "}</p>
          <TextField
            multiline={true}
            rows={1}
            style={{ marginBottom: 20, marginTop: 10 }}
            size="small"
            type="text"
            value={ainame}
            onChange={(event) => {
              set_ainame(event.target.value);
            }}
            placeholder="ai name"
          >
            {" "}
          </TextField>
        </div>
        <Button type="submit" variant="outlined">
          Save
        </Button>
      </form>
    </div>
  );
}

export default BlipPrompt;
