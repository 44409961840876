import { Box, Grid, Input, Slider, Typography } from "@mui/material";
import React from "react";

export function SliderCustom(props: {
  value: number;
  setValue: any;
  title: string;
  min: number;
  max: number;
  step: number;
  defaultValue?: number;
  onChange: any;
}) {
  let { value, setValue, title, min, max, step, defaultValue, onChange } =
    props;
  const handleSliderChangeTemperature = (
    event: Event,
    newValue: number | any
  ) => {
    onChange();
    setValue(newValue);
  };
  const handleInputChangeTemperature = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChange();
    setValue(event.target.value === "" ? 0 : Number(event.target.value));
  };
  const handleBlurTemperature = () => {
    if (value < min) {
      setValue(min);
    } else if (value > max) {
      setValue(max);
    }
  };
  return (
    <Box sx={{ width: 300 }}>
      <Typography id="input-slider" gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof value === "number" ? value : 0}
            onChange={handleSliderChangeTemperature}
            aria-labelledby="input-slider"
            max={max}
            min={min}
            step={step}
            defaultValue={defaultValue}
          />
        </Grid>
        <Grid item xs>
          <Input
            value={value}
            size="small"
            onChange={handleInputChangeTemperature}
            onBlur={handleBlurTemperature}
            inputProps={{
              step: { step },
              min: { min },
              max: { max },
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
