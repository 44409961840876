import {Cookies} from 'react-cookie';
const cookies = new Cookies();
// cookie expired date range
let maxAge: number = 60 * 60 * 24 * 7; // 1 week
// cookie names for login
export const COOKIE_NAME_VALUES:{
  [key:string]:string
} = {
  email: process.env.REACT_APP_COOKIE_LOGIN_NAME1 ? process.env.REACT_APP_COOKIE_LOGIN_NAME1:'',
  userName: process.env.REACT_APP_COOKIE_LOGIN_NAME2 ? process.env.REACT_APP_COOKIE_LOGIN_NAME2:'',
  loginFlag: process.env.REACT_APP_COOKIE_LOGIN_NAME3 ? process.env.REACT_APP_COOKIE_LOGIN_NAME3:'',
  userHd: process.env.REACT_APP_COOKIE_LOGIN_NAME4 ? process.env.REACT_APP_COOKIE_LOGIN_NAME4:'',
  role: process.env.REACT_APP_COOKIE_LOGIN_NAME5 ? process.env.REACT_APP_COOKIE_LOGIN_NAME5:'',
};

const setCookie = (name:string, value:string) => {
  return cookies.set(name,value, {
    sameSite: 'none',
    path:'/',
    domain: '.ella.so',
    secure: true,
    maxAge: maxAge
  })
}
const getCookie = (name:string) => {
  return cookies.get(name);
}
const checkCookies = async () => {
  const cookieAllNames = Object.values(COOKIE_NAME_VALUES);
  for await (const cookieName of cookieAllNames) {
    const cookieValue = cookies.get(cookieName)
    if (!cookieValue) return false;
  };
  return true;
}
const removeCookie = (name:string, option?:any) => {
  return cookies.remove(name, {...option});
}
// cookie remove for logout
const removeAllCookies = async () => {
  const cookieAllNames = Object.values(COOKIE_NAME_VALUES);
  for await (const cookieName of cookieAllNames) {
    cookies.remove(cookieName);
  };
  return true;
}
export const CookieTools = {
  setCookie,
  getCookie,
  removeCookie,
  removeAllCookies,
  checkCookies,
}