import BlipComponent from "./components/Blip";

export default function PlainChat() {
  return (
    <div className="App">
      {/* <div
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          margin: 0,
        }}
      >
        <h2> Blip Image </h2>
      </div> */}
      <BlipComponent />
    </div>
  );
}
