import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { SliderCustom } from "../../../components/common/slider.component";
import myUIConstants, {
  JSON_FILE_NAMES,
} from "../../../shared/constants/constants";
import { readPromptJson, savePromptJson } from "../../../shared/utils";
import React from "react";
import { TPromptData } from "shared/types";

function KipaRagPrompt() {
  const text =
    "child name must be {CHILDNAME}, ai name must be {AINAME}, your input must be {INPUT} (required), last N turns must be {CHAT_HISTORY}";
  const [prompt, set_prompt] = useState("");
  const [top_p, set_top_p] = useState(-1);
  const [temperature, set_temperature] = useState(-1);
  const [chat_prompt_size, set_chat_prompt_size] = useState(-1);
  const [embedding_chunk_size, set_embedding_chunk_size] = useState(-1);
  const [max_new_tokens, set_max_new_tokens] = useState(-1);
  const [repetition_penalty, set_repetition_penalty] = useState(-1);
  const [top_k, set_top_k] = useState(-1);
  const [received, set_received] = useState(false);
  const [username, set_username] = useState("");
  const [ainame, set_ainame] = useState("");
  const onPageOpen = async () => {
    if (!received) {
      const data: TPromptData = await readPromptJson(
        JSON_FILE_NAMES.testKipaRagPrompt
      );
      set_prompt(data.prompt);
      set_top_p(data.top_p);
      set_temperature(data.temperature);
      set_chat_prompt_size(data.chat_prompt_size);
      set_max_new_tokens(data.max_new_tokens);
      set_repetition_penalty(data.repetition_penalty);
      set_top_k(data.top_k);
      set_received(true);
      set_ainame(data.ainame);
      set_username(data.username);
      set_embedding_chunk_size(
        data.embedding_chunk_size ? data.embedding_chunk_size : 4
      );
    }
  };
  const onUpdatePromptData = () => {
    const dto: TPromptData = {
      prompt,
      temperature,
      top_p,
      max_new_tokens,
      chat_prompt_size,
      repetition_penalty,
      top_k,
      ainame,
      username,
      embedding_chunk_size,
    };
    savePromptJson(
      {
        ...dto,
      },
      JSON_FILE_NAMES.testKipaRagPrompt
    );
  };
  onPageOpen();
  return (
    <div
      style={{
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10%",
      }}
    >
      <form
        style={{
          width: "80%",
          justifyContent: "center",
          alignItems: "center",
        }}
        onSubmit={(event) => {
          event.preventDefault();
          onUpdatePromptData();
        }}
      >
        <h2
          style={{ textAlign: "center", fontFamily: myUIConstants.fontFamily }}
        >
          KIPA AI Capacity Builder Prep
        </h2>
        <div style={{ marginBottom: 10 }}>
          {" "}
          <span style={{ fontSize: 20, fontWeight: "bold" }}>Prompt</span>:{" "}
          <span style={{ fontSize: 15, fontWeight: "bold" }}> ({text})</span>
        </div>
        <TextField
          fullWidth
          multiline={true}
          rows={20}
          style={{ marginBottom: 20 }}
          size="small"
          color="primary"
          type="text"
          value={prompt}
          onChange={(event) => {
            set_prompt(event.target.value);
            onUpdatePromptData();
          }}
          placeholder="Enter Text"
        ></TextField>
        <SliderCustom
          value={temperature}
          setValue={set_temperature}
          title={"Temperature"}
          min={0}
          max={1}
          step={0.01}
          onChange={() => {
            onUpdatePromptData();
          }}
        ></SliderCustom>

        <SliderCustom
          value={top_p}
          setValue={set_top_p}
          title={"Top P"}
          min={0}
          max={1}
          step={0.01}
          onChange={() => {
            onUpdatePromptData();
          }}
        ></SliderCustom>
        <SliderCustom
          value={top_k}
          setValue={set_top_k}
          title={"Top K"}
          min={0}
          max={100}
          step={1}
          onChange={() => {
            onUpdatePromptData();
          }}
        ></SliderCustom>
        <SliderCustom
          value={max_new_tokens}
          setValue={set_max_new_tokens}
          title={"Max New Tokens"}
          min={16}
          max={1024}
          step={2}
          onChange={() => {
            onUpdatePromptData();
          }}
        ></SliderCustom>
        <SliderCustom
          value={chat_prompt_size}
          setValue={set_chat_prompt_size}
          title={"Chat Prompt Size"}
          min={16}
          max={2048}
          step={4}
          onChange={() => {
            onUpdatePromptData();
          }}
        ></SliderCustom>
        <SliderCustom
          value={repetition_penalty}
          setValue={set_repetition_penalty}
          title={"Repetition Penalty"}
          min={0}
          max={2}
          step={0.01}
          onChange={() => {
            onUpdatePromptData();
          }}
        ></SliderCustom>

        <SliderCustom
          value={embedding_chunk_size}
          setValue={set_embedding_chunk_size}
          title={"Embedding Chunk Size"}
          min={1}
          max={100}
          step={1}
          onChange={() => {
            onUpdatePromptData();
          }}
        ></SliderCustom>
        <br></br>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>{"CHILDNAME:  "}</p>
          <TextField
            multiline={true}
            rows={1}
            style={{ marginBottom: 20, marginTop: 10 }}
            size="small"
            type="text"
            value={username}
            onChange={(event) => {
              set_username(event.target.value);
              onUpdatePromptData();
            }}
            placeholder="child name"
          >
            {" "}
          </TextField>
        </div>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>{"AINAME:  "}</p>
          <TextField
            multiline={true}
            rows={1}
            style={{ marginBottom: 20, marginTop: 10 }}
            size="small"
            type="text"
            value={ainame}
            onChange={(event) => {
              set_ainame(event.target.value);
              onUpdatePromptData();
            }}
            placeholder="ai name"
          >
            {" "}
          </TextField>
        </div>
        <Button type="submit" variant="outlined">
          Save
        </Button>
      </form>
    </div>
  );
}

export default KipaRagPrompt;
