import React from "react";
import { ChatFeed, Message } from "react-chat-ui";
import { Button } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATBOT_API_URL,
  CHATTING_AI_MESSAGE_ID,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  JSON_FILE_NAMES,
  NEW_CHATBOT_API_URL,
} from "../../../shared/constants/constants";
import {
  getChattingNameById,
  getStoppingStringsForAIRsp,
  parseMarkdownTable,
  postData,
  readChatJson,
  readPromptJson,
  removeUnnecessaryResponseFromAIResponse,
  saveChatJson,
} from "../../../shared/utils";
import myUIConstants from "../../../shared/constants/constants";

import {
  ChatComponentState,
  TAIRequestObj,
  TChatbotResponseData,
  TChatMessageElement,
  TChatGetResponseDTO,
  TPromptData,
  THistoryTurnOne,
} from "shared/types";

const URL_CHAT = CHATBOT_API_URL;
const CHAT_TYPE = 0;

async function GetAIAnswer(
  dto: TChatGetResponseDTO
): Promise<TChatbotResponseData> {
  const { text, selected, clear_previous, chat_type, file_name_read_prompt } =
    dto;
  const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
  const {
    temperature,
    top_k,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_p,
    ainame,
    username,
  } = promptData;
  const aiRequestObj: TAIRequestObj = {
    text,
    selected,
    clear_previous,
    prompt: promptData.prompt,
    temperature,
    top_p,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_k,
    ainame,
    username,
    chat_type,
    stopping_strings: getStoppingStringsForAIRsp(ainame, username),
  };
  const chatbotRspData: TChatbotResponseData = await postData(
    URL_CHAT,
    aiRequestObj
  );
  const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
    chatbotRspData;
  const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
    output,
    promptData.ainame,
    promptData.username
  );
  return {
    output: revisedAnswer,
    prompt,
    generate_ans_time,
    generate_prompt_time,
    speed,
  };
}

class PlainChatComponent extends React.Component<{}, ChatComponentState> {
  constructor() {
    super({});
    this.state = {
      messages: [],
      history: [],
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.plainChat,
      file_name_prompt: JSON_FILE_NAMES.plainPrompt,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      input_message: "",
    };
  }
  async componentDidMount() {
    const { history, prompt } = await readChatJson(this.state.file_name_chat);
    this.populateChatWindow(history, prompt);
  }

  populateChatWindow(history: THistoryTurnOne[], prompt: string) {
    const messageElements: TChatMessageElement[] = history.map(
      (hitoryTurn: THistoryTurnOne) => {
        return {
          id: hitoryTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownTable(hitoryTurn.message)}
              </ReactMarkdown>
            </div>
          ),
          senderName: getChattingNameById(hitoryTurn.id),
        };
      }
    );

    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
  }

  async onMessageSubmit(e: any) {
    e.preventDefault();
    const text = this.state.input_message;
    this.pushUserMessage(text);
    this.setState({ input_message: "" });
    const clearPrevious = this.state.history.length <= 1;
    const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
      await GetAIAnswer({
        text,
        selected: this.state.selected,
        clear_previous: clearPrevious,
        chat_type: this.state.chat_type,
        file_name_read_prompt: this.state.file_name_prompt,
      });
    this.pushEllaMessage(output, prompt);
    this.setState({
      generate_ans_time,
      generate_prompt_time,
      speed,
    });
  }

  pushUserMessage(message: string) {
    const prevState = this.state;
    const userMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.history.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messages.push(userMessage);
    this.setState(this.state);
  }

  pushEllaMessage(message: string, prompt: string) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const ellaMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });
    prevState.messages.push(ellaMessage);
    prevState.history.push({ message, id: CHATTING_AI_MESSAGE_ID });
    this.setState({ prompt });
    saveChatJson(this.state.history, prompt, this.state.file_name_chat);
  }

  onClearHistory(e: any) {
    e.preventDefault();
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveChatJson(
      this.state.history,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <h2
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
              marginBottom: 50,
            }}
          >
            Free Chat
          </h2>
          <div className="chatfeed-wrapper">
            <ChatFeed
              maxHeight={500}
              messages={this.state.messages}
              showSenderName
            />

            <form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
                this.onMessageSubmit(e)
              }
            >
              <input
                style={{ height: 50 }}
                onChange={(e) => {
                  this.setState({ input_message: e.target.value });
                }}
                value={this.state.input_message}
                placeholder="Type a message..."
              />
            </form>
          </div>
          <form
            style={{
              direction: "rtl",
              width: "100%",
              padding: 10,
              marginTop: 20,
              fontSize: 15,
            }}
            onSubmit={() => {}}
          >
            <Button
              onClick={(e) => this.onMessageSubmit(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="contained"
              color="success"
            >
              {" "}
              Submit{" "}
            </Button>
            <Button
              onClick={(e) => this.onClearHistory(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="outlined"
            >
              Clear History
            </Button>
          </form>
          <div
            style={{
              direction: "rtl",
              width: "100%",
              padding: 10,
              fontSize: 15,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {this.state.generate_ans_time !== -1 ? (
              <p>
                Prompt was generated in {this.state.generate_prompt_time} s.
                Answer was generated in {this.state.generate_ans_time} s. Speed
                is {this.state.speed} t/s
              </p>
            ) : null}
          </div>
          <div style={{ marginTop: "1500px" }}>
            <h3>Prompt looks like this:</h3>
            <textarea
              readOnly={true}
              rows={50}
              style={{
                marginTop: 0,
                marginBottom: 50,
                width: "100%",
                borderRadius: 5,
                padding: 5,
              }}
              color="primary"
              value={this.state.prompt}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
}

export default PlainChatComponent;
