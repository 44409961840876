import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./Home.module.scss";
import GoogleLoginButton from "../../components/common/loginButtons/GoogleLoginButton";
import { CookieTools } from "../../shared/utils/Cookies";
type Props = {};

const HomePage = (props: Props) => {
  const [isLogin, setIsLogin] = React.useState<boolean>(false);

  const copyrightDate = new Date();
  const year = copyrightDate.getFullYear();
  const initCheckLogin = async () => {
    const cookieCheck = await CookieTools.checkCookies();
    if ( cookieCheck) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }

  React.useEffect(()=>{
    if (!isLogin) {
      initCheckLogin();
    } else {
      initCheckLogin();
    }
  }, [isLogin])

  return (
    <div>
      <Container className={style.ContainerClass}>
        <Row className={style.RowClass}>
          <Col className={style.homeImageLogos} />
          <h1 className={style.homeH1Class}>
            {isLogin? 'Welcome to United Associates - Demo Site': 'Please Login'}
          </h1>
          <p className={style.homePClass}>
            <b>United Associates inc</b>
          </p>
          {!isLogin && (
            <GoogleLoginButton isIconButton={true} />
          )}
        </Row>
        <Row className={style.homeFooterTag}>
          <div>
            <p
              style={{
                margin: 0,
                textAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Copyright &copy; {year} United Associates Inc. All rights reserved.
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default HomePage;
