import React from "react";
import YanadooRagChatComponent from "./YanadooRagComponent";

export default function YanadooRagChat() {
  return (
    <div className="App">
      <YanadooRagChatComponent />
    </div>
  );
}
