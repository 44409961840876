// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/United_Associates_BI_Logo_(Small)_(Transparent).png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_homeImageLogos__vAyqC {\n  height: 400px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n}\n\n.Home_ContainerClass__6pXu- {\n  text-align: center;\n}\n\n.Home_RowClass__uzCGT {\n  justify-content: center;\n  align-items: center;\n}\n\n.Home_homeH1Class__MEOxM {\n  color: #000000;\n  font-size: 28px;\n  font-weight: 500;\n  line-height: 36px;\n  margin: 0px 0px 8px 0px;\n}\n\n.Home_homePClass__mQnQv {\n  line-height: 21px;\n  margin: 0px;\n  color: #333333;\n}\n\n.Home_homeFooterTag__fafCp {\n  bottom: 10px;\n  left: 0;\n  right: 0;\n  position: absolute;\n}", "",{"version":3,"sources":["webpack://./src/pages/home/Home.module.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,yDAAA;EACA,4BAAA;EACA,wBAAA;EACA,2BAAA;AAAF;;AAIA;EACE,kBAAA;AADF;;AAOA;EACE,uBAAA;EACA,mBAAA;AAJF;;AAMA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;AAHF;;AAKA;EACE,iBAAA;EACA,WAAA;EACA,cAAA;AAFF;;AAKA;EACE,YAAA;EACA,OAAA;EACA,QAAA;EACA,kBAAA;AAFF","sourcesContent":[".homeImageLogos {\n  //   width: 400px;\n  height: 400px;\n  background-image: url(\"../../assets/images/United_Associates_BI_Logo_\\(Small\\)_\\(Transparent\\).png\");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n  //   margin-bottom: 10px;\n  //   margin-top: 48px;\n}\n.ContainerClass {\n  text-align: center;\n  // margin: 0;\n  //   width: 100%;\n  //   height: 90vh;\n  //   padding-left: 1rem;\n}\n.RowClass {\n  justify-content: center;\n  align-items: center;\n}\n.homeH1Class {\n  color: #000000;\n  font-size: 28px;\n  font-weight: 500;\n  line-height: 36px;\n  margin: 0px 0px 8px 0px;\n}\n.homePClass {\n  line-height: 21px;\n  margin: 0px;\n  color: #333333;\n}\n\n.homeFooterTag {\n  bottom: 10px;\n  left: 0;\n  right: 0;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeImageLogos": "Home_homeImageLogos__vAyqC",
	"ContainerClass": "Home_ContainerClass__6pXu-",
	"RowClass": "Home_RowClass__uzCGT",
	"homeH1Class": "Home_homeH1Class__MEOxM",
	"homePClass": "Home_homePClass__mQnQv",
	"homeFooterTag": "Home_homeFooterTag__fafCp"
};
export default ___CSS_LOADER_EXPORT___;
