import React from "react";
import PdfChatComponent from "./PdfChatComponent";

export default function PdfChat() {
  return (
    <div>
      <PdfChatComponent />
    </div>
  );
}
