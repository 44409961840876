import React from "react";
import KipaRagChatComponent from "./KipaRagComponent";

export default function KipaRagChat() {
  return (
    <div className="App">
      <KipaRagChatComponent />
    </div>
  );
}
