import React from "react";
import PwcRagChatComponent from "./PwcRagComponent";

export default function PwcRagChat() {
  return (
    <div className="App">
      <PwcRagChatComponent />
    </div>
  );
}
