import React from "react";
import PlainChatComponent from "./PlainChatComponent";

export default function PlainChat() {
  return (
    <div className="App">
      <PlainChatComponent />
    </div>
  );
}
