import React from "react";
import MemoryChatComponent from "./MemoryChatComponent";

export default function MemoryChat() {
  return (
    <div className="App">
      <MemoryChatComponent />
    </div>
  );
}
