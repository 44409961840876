import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const googleClientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID
  ? process.env.REACT_APP_GOOGLE_CLIENT_ID
  : "";
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <CookiesProvider>
        <Provider store={store}>
          <CssBaseline />
          <App />
        </Provider>
      </CookiesProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
