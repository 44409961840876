import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import {CookieTools, COOKIE_NAME_VALUES} from '../../../shared/utils/Cookies'
import styles from './GoogleLoginButton.module.scss'

const GOOGLE_USERINFO_API:string=process.env.REACT_APP_USERINFO_API_URL ? process.env.REACT_APP_USERINFO_API_URL:'';
const LOGIN_API:string = process.env.REACT_APP_LOGIN_API_URL?process.env.REACT_APP_LOGIN_API_URL:'';
const ROLE_CHECK_API:string = process.env.REACT_APP_UA_HS_USERS_ROLE_CHECK_URL?process.env.REACT_APP_UA_HS_USERS_ROLE_CHECK_URL:'';

export default function GoogleLoginButton(props: {
  isIconButton?: boolean,
}) {
  
  // create google login function
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      
      const userInfo = await axios
        .get(GOOGLE_USERINFO_API, {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }).then(res => res.data);
      const data = {
        tokenResponse: {
          access_token: tokenResponse.access_token,
          hd: tokenResponse.hd,
          scope: tokenResponse.scope,
          token_type: tokenResponse.token_type,
        },
        userInfo: userInfo,
      };
      const rsp = await axios.post(LOGIN_API, data, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      }).then(async res => {
        if (res.data['role']!==0) {
          const adminRoleRsp = await axios.post(ROLE_CHECK_API, {
            access_email: data.userInfo.email,
            access_name: data.userInfo.name,
            access_hd: data.tokenResponse.hd
          },{
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }).then(res=>res.data.role);
          CookieTools.setCookie(COOKIE_NAME_VALUES.role, adminRoleRsp);
        }
        return res;
      });
      const cookieData = rsp.data;
      const value1 = cookieData['email']
      const value2 = cookieData['displayName']
      const value3 = cookieData['login']
      const value4 = data.userInfo.hd;
      CookieTools.setCookie(COOKIE_NAME_VALUES.email, value1);
      CookieTools.setCookie(COOKIE_NAME_VALUES.userName, value2);
      CookieTools.setCookie(COOKIE_NAME_VALUES.loginFlag, value3);
      CookieTools.setCookie(COOKIE_NAME_VALUES.userHd, value4);

      const isAuthenticated = CookieTools.getCookie(COOKIE_NAME_VALUES.loginFlag);
      if (isAuthenticated) {
        window.location.reload();
      }
    },
    onError: (errorResponse) => console.log(`google login error::\n${errorResponse}`),
  });
  // if (props.isIconButton) {
    return (
      <>
        <button className={styles.googleLoginIcon} type='button' onClick={()=> googleLogin()}>
            <svg data-name="Layer 1" className={styles.w5h5} id="Layer_1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <path className={styles.w5h5} d="M23.75,16A7.7446,7.7446,0,0,1,8.7177,18.6259L4.2849,22.1721A13.244,13.244,0,0,0,29.25,16" fill="#00ac47"/>
                <path className={styles.w5h5} d="M23.75,16a7.7387,7.7387,0,0,1-3.2516,6.2987l4.3824,3.5059A13.2042,13.2042,0,0,0,29.25,16" fill="#4285f4"/>
                <path className={styles.w5h5} d="M8.25,16a7.698,7.698,0,0,1,.4677-2.6259L4.2849,9.8279a13.177,13.177,0,0,0,0,12.3442l4.4328-3.5462A7.698,7.698,0,0,1,8.25,16Z" fill="#ffba00"/>
                <polygon fill="#2ab2db" points="8.718 13.374 8.718 13.374 8.718 13.374 8.718 13.374"/>
                <path className={styles.w5h5} d="M16,8.25a7.699,7.699,0,0,1,4.558,1.4958l4.06-3.7893A13.2152,13.2152,0,0,0,4.2849,9.8279l4.4328,3.5462A7.756,7.756,0,0,1,16,8.25Z" fill="#ea4435"/>
                <polygon fill="#2ab2db" points="8.718 18.626 8.718 18.626 8.718 18.626 8.718 18.626"/>
                <path className={styles.w5h5} d="M29.25,15v1L27,19.5H16.5V14H28.25A1,1,0,0,1,29.25,15Z" fill="#4285f4"/>
            </svg>
            {/* <svg data-name="Layer 1" className='invisible w-0 h-0' id="Layer_1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                <path className={styles.w5h5} d="M23.75,16A7.7446,7.7446,0,0,1,8.7177,18.6259L4.2849,22.1721A13.244,13.244,0,0,0,29.25,16" fill="#00ac47"/>
                <path className={styles.w5h5} d="M23.75,16a7.7387,7.7387,0,0,1-3.2516,6.2987l4.3824,3.5059A13.2042,13.2042,0,0,0,29.25,16" fill="#4285f4"/>
                <path className={styles.w5h5} d="M8.25,16a7.698,7.698,0,0,1,.4677-2.6259L4.2849,9.8279a13.177,13.177,0,0,0,0,12.3442l4.4328-3.5462A7.698,7.698,0,0,1,8.25,16Z" fill="#ffba00"/>
                <polygon fill="#2ab2db" points="8.718 13.374 8.718 13.374 8.718 13.374 8.718 13.374"/>
                <path className={styles.w5h5} d="M16,8.25a7.699,7.699,0,0,1,4.558,1.4958l4.06-3.7893A13.2152,13.2152,0,0,0,4.2849,9.8279l4.4328,3.5462A7.756,7.756,0,0,1,16,8.25Z" fill="#ea4435"/>
                <polygon fill="#2ab2db" points="8.718 18.626 8.718 18.626 8.718 18.626 8.718 18.626"/>
                <path className={styles.w5h5} d="M29.25,15v1L27,19.5H16.5V14H28.25A1,1,0,0,1,29.25,15Z" fill="#4285f4"/>
            </svg> */}
        </button>
      </>
    )
  // }
  //  else {
  //   return <Button onClick={()=>googleLogin()} >Login with Google🚀</Button>
  // }
}