import React from "react";
import "./styles.css";
import { Button, Divider, FormGroup, TextField } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import * as fs from "fs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import myUIConstants from "../../../shared/constants/constants";

async function writeToJson(json) {
  fs.writeFile(
    "history_documents/info.json",
    JSON.stringify(json),
    function (err) {
      if (err) {
        console.log(err);
      }
    }
  );
}

async function readFromJson() {
  const jsonString = fs.readFileSync("history_documents/info.json", "utf-8");
  const config = JSON.parse(jsonString);
  return config;
}

async function postData(url, data) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response; // parses JSON response into native JavaScript objects
}

//documents_arr:
//{pdf_path, audio_path, document_name}

class TTSWhisperComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      documentsArr: [],
      isCreate: true,
      documentIndex: -1,
      fileName: "",
      fileContents: "",
      speakerName: "alloy",
      aiRequestWaiting: false,
      src: "",
      blob: null,
      isAudioErr: false,
      maxTextLen: 4000,
    };
  }

  async componentDidMount() {
    const documentsConfig = readFromJson();
    const documentsArr =
      documentsConfig && documentsConfig.documentsArr
        ? documentsConfig.documentsArr
        : [];
    this.setState({
      documentsArr,
    });
  }
  setSelected(ind) {
    this.setState({ documentIndex: ind, isCreate: false });
  }
  getButtons() {
    const buttonArr = [];
    for (const docObj of this.state.documentsArr) {
      buttonArr.push(
        <Button
          onClick={() => this.setSelected(docObj.orderIndex)}
          style={{
            width: "100%",
            height: "50px",
            textTransform: "none",
            fontSize: "15px",
            color: "black",
            backgroundColor:
              this.state.documentIndex == docObj.orderIndex ? "grey" : "white",
            borderColor: "black",
            marginBottom: "10px",
          }}
          variant="outlined"
        >
          {docObj.name}
        </Button>
      );
    }
    return buttonArr;
  }
  onPress(user) {
    this.setState({ curr_user: user });
  }
  onFileChange = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      this.setState({ fileContents: text.substring(0, this.state.maxTextLen) });
    };
    reader.readAsText(event.target.files[0]);
    this.setState({ fileName: event.target.files[0].name });
  };
  onSpeakingNameChange = (event) => {
    event.preventDefault();
    this.setState({ speakerName: event.target.value });
    console.log(event.target);
  };

  async onGetAIAudio(event) {
    event.preventDefault();
    if (this.state.fileContents.length == 0) return;
    this.setState({ aiRequestWaiting: true, isAudioErr: false });
    const rsp = postData(process.env.REACT_APP_WHISPER_API_URL, {
      text: this.state.fileContents,
      name: this.state.speakerName,
    });
    rsp
      .then((data) => {
        return data.blob();
      })
      .then((blob) => {
        this.setState({
          blob,
          aiRequestWaiting: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isAudioErr: true });
      });
  }
  saveDoc = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <div
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
              marginBottom: 50,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ margin: 10 }}>Whisper TTS</h2>
            <p> Price: $0.015 / 1K characters</p>
          </div>

          <div>
            <input
              style={{
                padding: 20,
                border: "1px solid grey",
                display: "flex",
                flexDirection: "column",
              }}
              type="file"
              onChange={this.onFileChange}
            />
          </div>
          <div style={{ width: "100%" }}>
            <h3>Document contents: {this.state.fileName}</h3>
            <textarea
              fullWidth
              width={1000}
              maxLength={4000}
              multiline={true}
              readOnly={false}
              rows={50}
              style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
              size="small"
              color="primary"
              type="text"
              value={this.state.fileContents}
              onChange={(e) => {
                this.setState({
                  fileContents: e.target.value.substring(
                    0,
                    this.state.maxTextLen
                  ),
                });
              }}
            ></textarea>
            <div id="the-count">
              <span id="current">{this.state.fileContents.length}</span>
              <span id="maximum">/ {this.state.maxTextLen}</span>
            </div>
          </div>
          <h3>Generate Audio</h3>
          <div
            style={{
              padding: 20,
              border: "1px solid grey",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 40, width: "50%" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Speaker Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={this.state.speakerName}
                  onChange={this.onSpeakingNameChange}
                  label="Speaking Name"
                >
                  <MenuItem value={"alloy"}>Alloy</MenuItem>
                  <MenuItem value={"echo"}>Echo</MenuItem>
                  <MenuItem value={"fable"}>Fable</MenuItem>
                  <MenuItem value={"onyx"}>Onyx</MenuItem>
                  <MenuItem value={"nova"}>Nova</MenuItem>
                  <MenuItem value={"shimmer"}>Shimer</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                style={{ height: "10", width: "30%" }}
                disabled={
                  this.state.aiRequestWaiting ||
                  this.state.fileContents.length === 0
                }
                onClick={(e) => {
                  this.onGetAIAudio(e);
                }}
              >
                Create
              </Button>
            </div>

            <div style={{ paddingTop: 20 }}>
              {this.state.blob ? (
                <ReactAudioPlayer
                  src={URL.createObjectURL(this.state.blob)}
                  controls
                />
              ) : this.state.isAudioErr ? (
                <p>Audio Generation Error</p>
              ) : (
                <p>No audio</p>
              )}
            </div>
            {/* <div style={{ paddingTop: 20 }}>
              {this.state.blob ? (
                <Button
                  variant="contained"
                  color="success"
                  onClick={(e) => this.saveDoc(e)}
                >
                  Save
                </Button>
              ) : null}
            </div> */}
          </div>
        </div>
        <div
          style={{
            width: "300px",
            height: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <div>
            <h3 style={{ marginTop: 0 }}>Select the document</h3>
            {this.getButtons()}
            <Button
              onClick={() => {
                this.setState({ isCreate: true });
              }}
              style={{
                width: "100%",
                height: "100px",
                textTransform: "none",
                fontSize: "15px",
                color: "black",
                backgroundColor: this.state.isCreate ? "grey" : "white",
                borderColor: "black",
                marginBottom: "10px",
              }}
              variant="outlined"
            >
              Create new
            </Button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default TTSWhisperComponent;
