import React from "react";
import TTSWhisperComponent from "./components/TTS-whisper";

export default function TTSWhisper() {
  return (
    <div className="App">
      <TTSWhisperComponent />
    </div>
  );
}
