export const CHATBOT_API_URL =
  "https://missing-cooler-ton-sku.trycloudflare.com/api";
export const NEW_CHATBOT_API_URL =
  "https://anchor-accident-supreme-clocks.trycloudflare.com/api";

export const CHAT_KOR_API =
  "https://signed-operated-marks-smtp.trycloudflare.com/api";

export const COT_STREAM_URL =
  "wss://rebate-pond-computation-mexican.trycloudflare.com/api";

export const myUIConstants = {
  fontFamily: "Grifter, sans-serif",
};

export const JSON_FILE_NAMES = {
  plainChat: "plain_chat",
  plainPrompt: "plain_prompt",
  compareChat: "compare_chat",
  comparePrompt: "compare_prompt",
  plainKrChat: "plain_kr_chat",
  plainKrPrompt: "plain_kr_prompt",
  pdfChat: "pdf_chat",
  pdfPrompt: "pdf_prompt",
  testYanadooRagChat: "test_yanadoo_rag_chat",
  testYanadooRagPrompt: "test_yanadoo_rag_prompt",
  testYanadoooRagBackupHistory: "test_yanadoo_rag_backup_history",
  testKipaRagChat: "test_kipa_rag_chat",
  testKipaRagPrompt: "test_kipa_rag_prompt",
  testKipaRagBackupHistory: "test_kipa_rag_backup_history",
  testPwcRagChat: "test_pwc_rag_chat",
  testPwcRagPrompt: "test_pwc_rag_prompt",
  testPwcRagBackupHistory: "test_pwc_rag_backup_history",
  rolePlayChat: "roleplay_chat",
  rolePlayPrompt: "roleplay_prompt",
};

export const URL_JSON_BACKEND_READ = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/read`;
export const URL_JSON_BACKEND_WRITE = `${process.env.REACT_APP_JSON_BACKEND_HOST}/json/write`;

export const users: any = {
  0: "You",
  1: "Ella",
};
export default myUIConstants;

export const AINAME_PLACEHOLDER = "{AINAME}";
export const CHILDNAME_PLACEHOLDER = "{CHILDNAME}";

export const CHATTING_USER_MESSAGE_ID = 0;
export const CHATTING_AI_MESSAGE_ID = 1;
export const CHATTING_USER_NAME = "You";
export const CHATTING_AI_NAME = "Ella";
