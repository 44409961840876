import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#131313",
    color: "#fff",
    hoverBg: "#1e293a",
    activeBg: "#1e253a",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  // mainBg: colors.grey["100"],
  mainBg: "white",
};

export default colorConfigs;
