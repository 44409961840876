import React from "react";

import { Button } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getData, postData } from "../../../shared/utils";
import { SliderCustom } from "../../../components/common/slider.component";
import myUIConstants from "../../../shared/constants/constants";
const SHOW_LANG_CODE = "en-US";

class TTSGoogleComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      isCreate: true,
      documentIndex: -1,
      fileName: "",
      fileContents: "",
      speakerName: "",
      aiRequestWaiting: false,
      src: "",
      blob: null,
      isAudioErr: false,
      maxTextLen: 4000,
      codeToVoiceObjArrMap: null,
      nameToSsmlGenderMap: null,
      pitch: 0,
      speed: 1,
      languageCode: SHOW_LANG_CODE,
      ssmlGender: "",
    };
  }

  async componentDidMount() {
    await this.onGetVoices();
  }

  setSelected(ind) {
    this.setState({ documentIndex: ind, isCreate: false });
  }

  onPress(user) {
    this.setState({ curr_user: user });
  }

  onFileChange = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      this.setState({ fileContents: text.substring(0, this.state.maxTextLen) });
    };
    reader.readAsText(event.target.files[0]);
    this.setState({ fileName: event.target.files[0].name });
  };

  onSpeakingNameChange = (event) => {
    event.preventDefault();
    let ssmlGender = "";
    if (this.state.nameToSsmlGenderMap)
      ssmlGender = this.state.nameToSsmlGenderMap.get(event.target.value);
    console.log(ssmlGender);
    this.setState({
      speakerName: event.target.value,
      ssmlGender,
    });
  };

  onLangageCodeChange = (event) => {
    event.preventDefault();
    this.setState({
      languageCode: event.target.value,
    });
  };

  async onGetVoices() {
    await getData(process.env.REACT_APP_GOOGLE_TTS_LIST_VOICES_API_URL, {})
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        const voices = data.voices;
        const codeToVoiceObjArrMap = new Map();
        const nameToSsmlGenderMap = new Map();
        for (const voiceObj of voices) {
          const { languageCodes, name, ssmlGender, naturalSampleRateHertz } =
            voiceObj;
          const languageCode = languageCodes[0];
          if (!codeToVoiceObjArrMap.has(languageCode)) {
            codeToVoiceObjArrMap.set(languageCode, []);
          }
          codeToVoiceObjArrMap.get(languageCode).push({
            name,
            languageCode,
            ssmlGender,
            naturalSampleRateHertz,
          });
          nameToSsmlGenderMap.set(name, ssmlGender);
        }
        this.setState({ codeToVoiceObjArrMap, nameToSsmlGenderMap });
      });
  }

  async onGetAIAudio(event) {
    event.preventDefault();
    if (this.state.fileContents.length === 0) return;
    this.setState({ aiRequestWaiting: true, isAudioErr: false });
    postData(process.env.REACT_APP_GOOGLE_TTS_API_URL, {
      text: this.state.fileContents,
      name: this.state.speakerName,
      pitch: this.state.pitch,
      languageCode: this.state.languageCode,
      speed: this.state.speed,
    })
      .then((data) => {
        return data.blob();
      })
      .then((blob) => {
        this.setState({
          blob,
          aiRequestWaiting: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isAudioErr: true });
      });
  }

  generateLangCodeComponents() {
    const componentArr = [];
    if (!this.state.codeToVoiceObjArrMap) return [];
    this.state.codeToVoiceObjArrMap.forEach((val, key) => {
      componentArr.push(<MenuItem value={key}>{key}</MenuItem>);
    });
    return componentArr;
  }

  generateSpeakingNameComponents() {
    const componentArr = [];
    if (!this.state.codeToVoiceObjArrMap) return [];
    for (const voiceObj of this.state.codeToVoiceObjArrMap.get(
      this.state.languageCode
    )) {
      componentArr.push(
        <MenuItem value={voiceObj.name}>{voiceObj.name}</MenuItem>
      );
    }
    return componentArr;
  }
  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <div
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
              marginBottom: 50,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ margin: 10 }}>Google TTS</h2>
            <p> Price: $0.016 / 1K characters</p>
          </div>
          <div>
            <input
              style={{
                padding: 20,
                border: "1px solid grey",
                display: "flex",
                flexDirection: "column",
              }}
              type="file"
              onChange={this.onFileChange}
            />
          </div>
          <div style={{ width: "100%" }}>
            <h3>Document contents: {this.state.fileName}</h3>
            <textarea
              fullWidth
              width={1000}
              maxLength={4000}
              multiline={true}
              readOnly={false}
              rows={50}
              style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
              size="small"
              color="primary"
              type="text"
              value={this.state.fileContents}
              onChange={(e) => {
                this.setState({
                  fileContents: e.target.value.substring(
                    0,
                    this.state.maxTextLen
                  ),
                });
              }}
            ></textarea>
            <div id="the-count">
              <span id="current">{this.state.fileContents.length}</span>
              <span id="maximum">/ {this.state.maxTextLen}</span>
            </div>
          </div>
          <h3>Generate Audio</h3>
          <div
            style={{
              padding: 20,
              border: "1px solid grey",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "50%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 40, width: "50%" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Langage Code
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.languageCode}
                      onChange={this.onLangageCodeChange}
                      label="Speaking Name"
                    >
                      {this.generateLangCodeComponents()}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ m: 1, minWidth: 40, width: "50%" }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Speaker Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.speakerName}
                      onChange={this.onSpeakingNameChange}
                      label="Speaking Name"
                    >
                      {this.generateSpeakingNameComponents()}
                    </Select>
                  </FormControl>
                </div>
                <p style={{ fontSize: 12 }}>Gender:</p>
                <textarea
                  fullWidth
                  width={1000}
                  maxLength={4000}
                  readOnly={true}
                  value={this.state.ssmlGender}
                ></textarea>
                <br></br>
                <br></br>

                <SliderCustom
                  value={this.state.pitch}
                  setValue={(val) => this.setState({ pitch: val })}
                  title={"Pitch"}
                  min={-20}
                  max={20}
                  step={0.01}
                ></SliderCustom>

                <SliderCustom
                  value={this.state.speed}
                  setValue={(val) => this.setState({ speed: val })}
                  title={"Speed"}
                  min={0.25}
                  max={4}
                  step={0.01}
                  defaultValue={1}
                ></SliderCustom>
              </div>

              <Button
                variant="contained"
                style={{ height: "10", width: "30%" }}
                disabled={
                  this.state.speakerName.length === 0 ||
                  this.state.fileContents.length === 0 ||
                  this.state.aiRequestWaiting
                }
                onClick={(e) => {
                  this.onGetAIAudio(e);
                }}
              >
                Create
              </Button>
            </div>

            <div style={{ paddingTop: 20 }}>
              {this.state.blob ? (
                <ReactAudioPlayer
                  src={URL.createObjectURL(this.state.blob)}
                  controls
                />
              ) : this.state.isAudioErr ? (
                <p>Audio Generation Error</p>
              ) : (
                <p>No audio</p>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "300px",
            height: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <div>
            <h3 style={{ marginTop: 0 }}>Select the document</h3>
            <Button
              onClick={() => {
                this.setState({ isCreate: true });
              }}
              style={{
                width: "100%",
                height: "100px",
                textTransform: "none",
                fontSize: "15px",
                color: "black",
                backgroundColor: this.state.isCreate ? "grey" : "white",
                borderColor: "black",
                marginBottom: "10px",
              }}
              variant="outlined"
            >
              Create new
            </Button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default TTSGoogleComponent;
