import React from "react";
import CompareChatComponent from "./CompareChatComponent";

export default function CompareChat() {
  return (
    <div className="App">
      <CompareChatComponent />
    </div>
  );
}
