import React from "react";
import { ChatFeed, Message } from "react-chat-ui";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import "../components/styles.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  CHATBOT_API_URL,
  CHATTING_AI_MESSAGE_ID,
  CHATTING_AI_NAME,
  CHATTING_USER_MESSAGE_ID,
  CHATTING_USER_NAME,
  JSON_FILE_NAMES,
  NEW_CHATBOT_API_URL,
  URL_JSON_BACKEND_READ,
  URL_JSON_BACKEND_WRITE,
} from "../../../shared/constants/constants";
import {
  readChatJson,
  readPromptJson,
  saveChatJson,
  removeUnnecessaryResponseFromAIResponse,
  parseMarkdownTable,
  getResponseFromLLMServer,
  getChattingNameById,
  postData,
  getStoppingStringsForAIRsp,
  fromHistoryToString,
  fromHistoryToOldHistory,
  getRagChunks,
  getChatGPTResponse,
} from "../../../shared/utils";
import myUIConstants from "../../../shared/constants/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ChatComponentState,
  TAIRequestObj,
  TChatbotResponseData,
  TChatMessageElement,
  THistoryTurnOne,
  TPromptData,
} from "shared/types";

const CHAT_TYPE = 0;
const URL_CHAT = NEW_CHATBOT_API_URL;
const pwc_document_key_arr = ["pwc"];

type BackupObj = {
  timestamp: number;
  history: THistoryTurnOne[];
  prompt: string;
};

async function readBackupChatHistory(file_name: string): Promise<BackupObj[]> {
  const url = URL_JSON_BACKEND_READ;
  try {
    const backupRsp: { curBackup: BackupObj[] } = await postData(url, {
      file_name,
    });
    return backupRsp.curBackup;
  } catch (error) {
    return [];
  }
}

async function updateBackup(
  curBackup: BackupObj[],
  file_name: string
): Promise<void> {
  const url = URL_JSON_BACKEND_WRITE;
  await postData(url, { curBackup, file_name });
}

function backupChatHistory(
  history: THistoryTurnOne[],
  prompt: string,
  file_name: string,
  curBackup: BackupObj[]
): void {
  const backupObj: BackupObj = {
    timestamp: new Date().getTime(),
    history,
    prompt,
  };
  //dont save duplicates
  for (const oldObj of curBackup) {
    if (JSON.stringify(oldObj.history) === JSON.stringify(backupObj.history)) {
      return;
    }
  }
  curBackup.push(backupObj);
  updateBackup(curBackup, file_name);
}

async function GetAIAnswer(
  history: THistoryTurnOne[],
  file_name_read_prompt: string,
  document_key: string
): Promise<TChatbotResponseData> {
  const promptData: TPromptData = await readPromptJson(file_name_read_prompt);
  const {
    temperature,
    top_p,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_k,
    ainame,
    username,
    embedding_chunk_size,
  } = promptData;
  const lastUserMsg = history[history.length - 1].message;
  const chunkArr = await getRagChunks(
    lastUserMsg,
    embedding_chunk_size ? embedding_chunk_size : 4,
    document_key
  );
  promptData.prompt = promptData.prompt.replace(
    /{RAG_DOCUMENTS}/g,
    chunkArr.join("\n")
  );
  promptData.prompt = promptData.prompt.replace(
    /{CONVERSATION_HISTORY}/g,
    fromHistoryToString(history, ainame, username)
  );
  promptData.prompt = promptData.prompt.replace(/{AINAME}/g, ainame);
  promptData.prompt = promptData.prompt.replace(/{CHILDNAME}/g, username);
  const aiRequestObj: TAIRequestObj = {
    text: "",
    selected: 4,
    clear_previous: false,
    prompt: promptData.prompt,
    temperature,
    top_p,
    max_new_tokens,
    chat_prompt_size,
    repetition_penalty,
    top_k,
    ainame,
    username,
    chat_type: CHAT_TYPE,
    stopping_strings: getStoppingStringsForAIRsp(ainame, username),
  };
  console.log(aiRequestObj);

  const rsp = await getChatGPTResponse({
    prompt_text: promptData.prompt,
    max_tokens: max_new_tokens,
    top_p: top_p,
    frequency_penalty: repetition_penalty,
    presence_penalty: repetition_penalty,
    engine: "gpt-4o-mini",
    temperature: temperature,
    ainame,
    username,
    stop_strings: getStoppingStringsForAIRsp(ainame, username),
  });
  const { output, generate_ans_time, speed } = rsp;
  const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
    output,
    ainame,
    username
  );
  return {
    output: revisedAnswer,
    prompt: promptData.prompt,
    generate_ans_time,
    generate_prompt_time: 0,
    speed,
  };

  // const chatbotRspData: TChatbotResponseData = await postData(
  //   URL_CHAT,
  //   aiRequestObj
  // );
  // console.log(chatbotRspData);
  // const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
  //   chatbotRspData;
  // const revisedAnswer = removeUnnecessaryResponseFromAIResponse(
  //   output,
  //   ainame,
  //   username
  // );
  // return [
  //   revisedAnswer,
  //   prompt,
  //   generate_ans_time,
  //   generate_prompt_time,
  //   speed,
  // ];
  // const chatbotRspData = await getLLMResponse({
  //   history: fromHistoryToOldHistory(history),
  //   ai_name: ainame,
  //   child_name: username,
  // });
  // return [
  //   removeUnnecessaryResponseFromAIResponse(
  //     chatbotRspData.text,
  //     ainame,
  //     username
  //   ),
  //   chatbotRspData.prompt_text,
  //   0,
  //   0,
  //   0,
  // ];
}

type PwcChatComponentState = ChatComponentState & {
  file_name_backup_history: string;
  isBackedAlready: boolean;
  buttonArr: any[];
  selectedDocumentKey: string;
};

class PwcRagChatComponent extends React.Component<{}, PwcChatComponentState> {
  constructor() {
    super({});
    this.state = {
      messages: [],
      history: [],
      selected: 4,
      prompt: "",
      chat_type: CHAT_TYPE,
      file_name_chat: JSON_FILE_NAMES.testPwcRagChat,
      file_name_prompt: JSON_FILE_NAMES.testPwcRagPrompt,
      file_name_backup_history: JSON_FILE_NAMES.testPwcRagBackupHistory,
      speed: -1,
      generate_ans_time: -1,
      generate_prompt_time: -1,
      isBackedAlready: false,
      buttonArr: [],
      input_message: "",
      selectedDocumentKey: pwc_document_key_arr[0],
    };
  }

  async componentDidMount() {
    const { history, prompt } = await readChatJson(this.state.file_name_chat);
    this.populateChatWindow(history, prompt);
    let document_key: string = pwc_document_key_arr[0];
    if (sessionStorage.getItem("document_key"))
      document_key = sessionStorage.getItem("document_key") as string;
    this.setState({
      buttonArr: await this.getBackupButtonArr(),
      selectedDocumentKey: document_key,
    });
  }

  populateChatWindow(history: THistoryTurnOne[], prompt: string) {
    const messageElements: TChatMessageElement[] = history.map(
      (historyTurn: THistoryTurnOne) => {
        return {
          id: historyTurn.id,
          message: (
            <div style={{ color: "black", margin: 0 }}>
              <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
                {parseMarkdownTable(historyTurn.message)}
              </ReactMarkdown>
            </div>
          ),
          senderName: getChattingNameById(historyTurn.id),
        };
      }
    );
    this.setState({ messages: messageElements });
    this.setState({ history });
    this.setState({ prompt });
  }

  async onMessageSubmit(e: any) {
    e.preventDefault();
    const text = this.state.input_message;
    this.pushUserMessage(text);
    this.setState({ input_message: "" });
    const { output, prompt, generate_ans_time, generate_prompt_time, speed } =
      await GetAIAnswer(
        this.state.history,
        this.state.file_name_prompt,
        this.state.selectedDocumentKey
      );
    this.pushEllaMessage(output, prompt);
    this.setState({
      generate_ans_time,
      generate_prompt_time,
      speed,
    });
  }

  pushUserMessage(message: string) {
    const prevState = this.state;
    const newMessage = new Message({
      id: CHATTING_USER_MESSAGE_ID,
      message,
      senderName: CHATTING_USER_NAME,
    });
    prevState.history.push({ message, id: CHATTING_USER_MESSAGE_ID });
    prevState.messages.push(newMessage);
    this.setState(this.state);
  }

  pushEllaMessage(message: string, prompt: string) {
    const prevState = this.state;
    const messageElement = (
      <div style={{ color: "black", margin: 0 }}>
        <ReactMarkdown className="nomargin" remarkPlugins={[remarkGfm]}>
          {parseMarkdownTable(message)}
        </ReactMarkdown>
      </div>
    );
    const ellaMessage = new Message({
      id: CHATTING_AI_MESSAGE_ID,
      message: messageElement as any,
      senderName: CHATTING_AI_NAME,
    });
    prevState.messages.push(ellaMessage);
    prevState.history.push({ message, id: CHATTING_AI_MESSAGE_ID });
    this.setState({ prompt, isBackedAlready: false });
    saveChatJson(this.state.history, prompt, this.state.file_name_chat);
  }

  onClearHistory(e: any) {
    e.preventDefault();
    this.setState({ messages: [] });
    this.setState({ history: [] });
    saveChatJson(
      this.state.history,
      this.state.prompt,
      this.state.file_name_chat
    );
  }

  async onBackupDelete(e: any, timestamp: number) {
    const backupArr = await this.getBackupArr();
    const newArr: BackupObj[] = [];
    for (const obj of backupArr) {
      if (obj.timestamp !== timestamp) {
        newArr.push(obj);
      }
    }
    await updateBackup(newArr, this.state.file_name_backup_history);
    this.setState({ buttonArr: await this.getBackupButtonArr() });
  }

  onApplyBackupToChatWindow(
    e: any,
    history: THistoryTurnOne[],
    prompt: string
  ) {
    e.preventDefault();
    this.populateChatWindow(history, prompt);
  }

  async getBackupButtonArr(): Promise<any[]> {
    const backupArr = await this.getBackupArr();
    backupArr.sort((a, b) => b.timestamp - a.timestamp);
    const buttonArr: any[] = [];
    backupArr.forEach((backupObj) => {
      const { timestamp, history, prompt } = backupObj;
      buttonArr.push(
        <div>
          <Button
            onClick={(e) => this.onApplyBackupToChatWindow(e, history, prompt)}
            style={{ margin: 10, width: 400 }}
            type="submit"
            variant="outlined"
            color="success"
          >
            {new Date(timestamp).toISOString()}
          </Button>
          <Button
            onClick={(e) => this.onBackupDelete(e, timestamp)}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      );
    });
    return buttonArr;
  }

  async getBackupArr(): Promise<BackupObj[]> {
    const curBackupArr: BackupObj[] = await readBackupChatHistory(
      this.state.file_name_backup_history
    );
    return curBackupArr;
  }

  async onBackupCurrentChat(e: any) {
    e.preventDefault();
    const backupArr: BackupObj[] = await this.getBackupArr();
    backupChatHistory(
      this.state.history,
      this.state.prompt,
      this.state.file_name_backup_history,
      backupArr
    );
    this.setState({ isBackedAlready: true });
  }

  onDocumentChange(event: any) {
    event.preventDefault();
    this.setState({ selectedDocumentKey: event.target.value });
    sessionStorage.setItem("document_key", event.target.value);
    console.log(event.target);
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="container" style={{ marginTop: 0 }}>
          <h2
            style={{
              textAlign: "center",
              fontFamily: myUIConstants.fontFamily,
              marginBottom: 50,
            }}
          >
            PwC AI ConText Parser
          </h2>
          <div className="chatfeed-wrapper">
            <div style={{}}>
              {" "}
              <ChatFeed
                maxHeight={750}
                messages={this.state.messages} // Boolean: list of message objects
                showSenderName
                // height={500}
              />
            </div>

            <form onSubmit={(e) => this.onMessageSubmit(e)}>
              <input
                style={{ height: 50 }}
                onChange={(e) => {
                  this.setState({ input_message: e.target.value });
                }}
                value={this.state.input_message}
                placeholder="Type a message..."
              />
            </form>
          </div>

          <form
            style={{
              direction: "rtl",
              width: "100%",
              padding: 10,
              marginTop: 20,
              fontSize: 15,
            }}
            onSubmit={() => {}}
          >
            <Button
              onClick={(e) => this.onMessageSubmit(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="contained"
              color="success"
            >
              {" "}
              Submit{" "}
            </Button>
            <Button
              onClick={(e) => this.onClearHistory(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              variant="outlined"
            >
              Clear History
            </Button>
            <Button
              onClick={(e) => this.onBackupCurrentChat(e)}
              style={{ marginLeft: 20 }}
              type="submit"
              color="error"
              variant={this.state.isBackedAlready ? "contained" : "outlined"}
            >
              Backup current chat
            </Button>
          </form>
          <div
            style={{
              direction: "rtl",
              width: "100%",
              padding: 10,
              fontSize: 15,
              display: "flex",
              flexDirection: "row",
            }}
          >
            {this.state.generate_ans_time !== -1 ? (
              <p>
                Prompt was generated in {this.state.generate_prompt_time} s.
                Answer was generated in {this.state.generate_ans_time} s. Speed
                is {this.state.speed} t/s
              </p>
            ) : null}
          </div>
          <div style={{ marginTop: "1500px" }}>
            <h3>Prompt looks like this:</h3>
            <textarea
              readOnly={true}
              rows={50}
              style={{
                marginTop: 0,
                marginBottom: 50,
                width: "100%",
                borderRadius: 5,
                padding: 5,
              }}
              color="primary"
              value={this.state.prompt}
            ></textarea>
          </div>
          <div>
            <h3>Previous conversations:</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {this.state.buttonArr}
            </div>
          </div>
        </div>
        <FormControl
          style={{ marginTop: 100 }}
          variant="standard"
          sx={{ m: 1, minWidth: 40, width: "200px" }}
        >
          <InputLabel id="demo-simple-select-standard-label">
            Selected Document
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={this.state.selectedDocumentKey}
            onChange={(e) => this.onDocumentChange(e)}
            label="Selected Document"
          >
            <MenuItem value={pwc_document_key_arr[0]}>PWC Document</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default PwcRagChatComponent;
